<template>
  <div class="default-layout">
    <slot></slot>
  </div>
</template>
<script setup></script>
<script>
export default {
  components: {},
  props: {
    /*
     backgroundImage: {
     type: String,
     default: 'page-bg-texture.png',
     }
     */
  },
  data() {
    return {
      // showHeader: this.$store.getters['talron/showHeader'],
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
